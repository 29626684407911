<template>
  <b-card>
    <pocu-header></pocu-header>
    <b-row v-if="currentProject">
      <b-col cols="12" sm="10" md="8" lg="6" style="margin:auto">
        <h2 class="titluProiect text-secondary">Proiectul tău</h2>
        <b-row>
          <b-col>
            <b-form-group >
              <label style="font-weight:700" for="titluProiect">Titlu proiect</label>
              <b-form-textarea
                id="titluProiect"
                v-model="tempProject.nume"
                placeholder="Introdu titlul proiectului"
                rows="2"
                sieeze="sm"
                no-resize
                disabled
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label style="font-weight:700" for="idProiect">Cod SMIS</label>
              <b-form-input
                id="idProiect"
                type="number"
                v-model="tempProject.id_proiect"
                readonly
                />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label style="font-weight:700" for="oir">Regiune Implementare</label>
              <b-form-input
                id="oir"
                type="text"
                v-model="tempProject.oir"
                placeholder="Introdu Regiune Implementare"
                disabled
                />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <label style="font-weight:700" for="numeParteneri">Solicitant / parteneriat</label>
              <b-form-textarea
                  id="numeParteneri"
                  v-model="tempProject.nume_parteneri"
                  placeholder="Introdu numele partenerilor"
                  rows="2"
                  size="sm"
                  no-resize
                  disabled
                ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label style="font-weight:700" for="valoareProiect">Valoare Proiect</label>
              <b-form-input
                id="valoareProiect"
                type="text"
                v-model="valoareProiect"
                placeholder="Introdu valoarea proiectului"
                disabled
                />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label></label>
              <b-button
              variant="primary"
              @click="goToDocumentsUpload()"
              block
            >Încarcă documentele de buget</b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="currentProject && currentProject.license_key">
          <b-col>
            <p>Îți mulțumim că ai ales POCUmanager. Aceasta este licența ta.</p>
            <p class="license_number">{{currentProject.license_key}}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="deleteProject text-danger" v-b-modal.modal-delete-project>Șterge Proiect</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-else>
      <div id="no_content">
        <p>Nu ai nici un proiect. Poți adăuga un proiect nou folosind butonul de mai sus.</p>
      </div>
    </div>
    <b-modal v-if="currentProject"
      id="modal-delete-project"
      cancel-variant="outline-secondary"
      ok-title="Da, Șterge"
      cancel-title="Nu"
      centered
      title="Ștergere Proiect"
      @ok="deleteProjectOk"
    >
      <p>Ești sigur că vrei să ștergi proiectul <strong>POCU {{currentProjectSmisId}}</strong>?</p>
      <p>Ștergerea proiectului implică automat ștergerea licenței (dacă ai achiziționat una) iar pentru înrolarea ulterioară va trebui să contractezi o nouă licență.</p>
    </b-modal>
    <b-modal
      id="modal-project-deleted"
      title="Ștergere Proiect"
      ok-only
      ok-title="OK"
      centered
      v-model="showProjectDeletedModal"
      @hidden="resetProjectDeletedModal"
      >
      <p>Proiectul a fost șters</p>
    </b-modal>
  </b-card>
</template>

<script>
import axios from '@axios'
import {
  BModal, BRow, BCol, BCard, BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BFormCheckboxGroup, BIconNutFill, BSpinner, BFormTextarea,VBModal,
} from 'bootstrap-vue'
import PocuHeader from './parts/PocuHeader.vue'
import {mapState} from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  components:{
    BModal,
    BRow, 
    BCol, 
    BCard,
    PocuHeader,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    VBModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      tempProject:{},
      showProjectDeletedModal: false
    }
  },
  computed: {
    ...mapState('pocu',{
      currentProjectId : 'project_id',
      allProjects: 'projects'
    }),
    currentProject(){
      return this.allProjects[this.currentProjectId] ? this.allProjects[this.currentProjectId] : null
    },
    currentProjectSmisId(){
      return this.currentProject ? this.currentProject.id_proiect : ''
    },
    valoareProiect(){
      return this.tempProject.valoare_proiect ? this.tempProject.valoare_proiect + " lei" : ""
    },
  },
  mounted(){

  },
  watch: {
    currentProject(newVal,oldVal){
      this.tempProject = newVal
    }
  },
  methods: {
    resetProjectDeletedModal(){
      this.showProjectDeletedModal = false
    },
    deleteProjectOk(bvModalEvt){
      this.deleteProjectApi(this.currentProjectId)
    },
    deleteProjectApi(project_id){
      axios.delete('/pocu/deleteProject/',{
        params : {
          project_id : project_id,
        }
      })
        .then(response => {
          this.deleteProjectVuex(project_id)
          this.showProjectDeletedModal = true
        })
    },
    deleteProjectVuex(project_id){
      this.$store.commit('pocu/DELETE_PROJECT',project_id)
    },
    goToDocumentsUpload(){
      this.$router.push({name:'pocu-documentele-mele'});
    },
    validateProject(){
      axios.get('/pocu/validateProjectId',{
        params : {
          id_proiect : this.currentProject.id_proiect
        }
      })
      .then(response => {
        
      })
    },
  },
  created(){
    
  },
}
</script>

<style scoped>
#idProiect{
  -moz-appearance: textfield;
}
.license_number{
  font-size: 30px;
    font-weight: 700;
    color: #3ceed4;
}
#idProiect{
  color: #3ceed4;
  font-weight: 900;
  font-size: 22px;
  background-color:#fff;
  letter-spacing: 5px;
  text-align:center;
}
.titluProiect{
  font-size: 38px;
    /* color: #c2c2c2; */
    font-weight: 100;
    margin-bottom: 30px;
}
span.deleteProject{
  cursor:pointer;
}
#no_content{
  text-align:center;
}
</style>